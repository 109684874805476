const getExtendedTimes = (times) => {
  return times
    .reduce((accumulator, time) => {
      const hour = time.substring(0, 2);
      if (!accumulator.includes(hour)) {
        accumulator.push(hour);
      }

      return accumulator;
    }, [])
    .flatMap((time) => {
      const hour = time.substring(0, 2);

      const minutes = [
        `${hour}00`,
        `${hour}05`,
        `${hour}10`,
        `${hour}15`,
        `${hour}20`,
        `${hour}25`,
        `${hour}30`,
        `${hour}35`,
        `${hour}40`,
        `${hour}45`,
        `${hour}50`,
        `${hour}55`,
      ];

      return minutes;
    })
    .sort();
};

const getMidHours = (startTime, endTime) => {
  const startTimeHour = parseInt(startTime.substring(0, 2), 10);
  const endTimeHour = parseInt(endTime.substring(0, 2), 10);

  const midHours = [];

  for (let index = startTimeHour; index < endTimeHour; index++) {
    let midHourToString = '';
    if (index < 10) {
      midHourToString = `0${index}00`;
    } else {
      midHourToString = `${index}00`;
    }
    midHours.push(midHourToString);
  }

  return midHours;
};

module.exports = { getMidHours, getExtendedTimes };
