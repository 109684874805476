import * as styles from './modal.module.scss';

import React, { useRef } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick';
import useWindowPosition from '../../hooks/useWindowPosition';

const Modal = ({ title, onClose, visible, children }) => {
  const wrapperRef = useRef(null);
  const scrollPosition = useWindowPosition();

  const handleClose = () => {
    wrapperRef.current.classList.add(styles.modalVisible);

    setTimeout(() => {
      onClose();
    }, 500);
  };

  useOutsideClick(wrapperRef, handleClose);

  if (!visible) {
    return <></>;
  }

  return (
    <>
      <div className={styles.blurredPage} />
      <div className={styles.modalWrapper} ref={wrapperRef} style={{ top: scrollPosition }}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2 className={styles.modalTitle}>{title}</h2>
          </div>
          <div className={styles.modalBody}>{children}</div>
          <div className={styles.modalFooter}>
            <button type="button" onClick={handleClose} className={styles.modalCloseButton}>
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
