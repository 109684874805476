// extracted by mini-css-extract-plugin
export var blurredPage = "modal-module--blurredPage--v+FOz";
export var modalWrapper = "modal-module--modalWrapper--kFHfl";
export var fadein = "modal-module--fadein--WUouD";
export var modal = "modal-module--modal--DdNzX";
export var modalVisible = "modal-module--modalVisible--A7kdk";
export var fadeout = "modal-module--fadeout--xxXZz";
export var modalBody = "modal-module--modalBody--NFjOl";
export var modalHeader = "modal-module--modalHeader--5hFIa";
export var modalFooter = "modal-module--modalFooter--yzZt0";
export var modalCloseButton = "modal-module--modalCloseButton--53ssq";
export var modalTitle = "modal-module--modalTitle--tGy37";