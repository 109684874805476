import * as styles from './tab.module.scss';

import React, { useEffect, useState } from 'react';

const Tab = ({ children, active = 0, eventOccured, selectedGymIndex }) => {
  const [activeTab, setActiveTab] = useState(active);
  const [tabsData, setTabsData] = useState([]);

  useEffect(() => {
    setActiveTab(0);
  }, [eventOccured]);

  useEffect(() => {
    setActiveTab(selectedGymIndex);
  }, [selectedGymIndex]);

  useEffect(() => {
    const data = [];

    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;

      const {
        props: { tab, children },
      } = element;

      data.push({ tab, children });
    });

    setTabsData(data);
  }, [children]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabContent}>{tabsData[activeTab] && tabsData[activeTab].children}</div>
    </div>
  );
};

const TabPane = ({ children }) => {
  return children;
};

Tab.TabPane = TabPane;

export default Tab;
