import * as styles from './timetable-events.module.scss';

import React, { useState } from 'react';

import { Link } from 'gatsby';

import Modal from '../Modal';
import ExternalLinkIcon from '-!svg-react-loader!../../assets/external-link.svg';

const TimetableEventCard = ({ day, name, startTime, endTime, lesson_id, teacherName, teacherSlug, ageGroup, courseType }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCardClick = () => {
    if (!teacherSlug && !teacherName) return;

    setModalOpen(true);
  };

  return (
    <>
      <div
        className={`${styles.session} ${styles[day]} ${styles[lesson_id]}`}
        style={{ gridColumn: day, gridRow: `time-${startTime} / time-${endTime}` }}
        onClick={handleCardClick}
        role="presentation"
      >
        <h3 className={styles.sessionTitle}>
          <span>{name}</span>
          {ageGroup && (
            <>
              <span style={{ display: 'block' }}>{ageGroup}</span>
            </>
          )}
          <span className={styles.sessionTime}>
            {startTime?.substring(0, 2) + ':' + startTime?.substring(2, startTime?.length)}
            &nbsp;-&nbsp;
            {endTime?.substring(0, 2) + ':' + endTime?.substring(2, endTime?.length)}
          </span>
        </h3>
      </div>
      <Modal title={name} onClose={() => setModalOpen(false)} visible={modalOpen}>
        {teacherSlug && teacherName && (
          <div className={styles.modalContent}>
            <h3>Insegnante: </h3>
            <Link to={`/teachers#${teacherSlug}`}>
              <span role="img" aria-label="dancer">
                💃 {teacherName}
              </span>
            </Link>
            <br />
            <br />
          </div>
        )}
        {lesson_id !== 'individual_lesson' && (
          <a href={`/${courseType}/${lesson_id.replace('_', '-')}`} target="_blank" rel="noreferrer">
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <span style={{ marginRight: '0.5em' }}>Scopri di piu su questo corso!</span>
              <ExternalLinkIcon style={{ width: '1.5em', height: '1.5em' }} />
            </div>
          </a>
        )}
      </Modal>
    </>
  );
};

export default TimetableEventCard;
