import * as styles from './timetable-events.module.scss';

import { getExtendedTimes, getMidHours } from './helpers';

import React from 'react';
import TimetableEventCard from './TimetableEventCard';
import useWindowSize from '../../hooks/useWindowSize';

const weekdays = ['', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

const weekdaysTranslation = {
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedì',
  friday: 'Venerdì',
  saturday: 'Sabato',
};

const TimetableEvents = ({ lessons, courseType }) => {
  const { width: windowWidth } = useWindowSize();

  // This logic allows an even more compact layout
  const allLessonsTimes = Object.keys(lessons).flatMap((day) =>
    lessons[day].flatMap(({ startTime, endTime }) => {
      // Include times in between an event
      const allTimes = getMidHours(startTime, endTime);
      allTimes.push(startTime, endTime);
      return allTimes;
    })
  );
  const times = [...new Set(allLessonsTimes)];
  if (times.length === 0) {
    return <p> Non ci sono ancora orari in questa palestra. Prova piu' tardi per vedere aggiornamenti :D</p>;
  }

  const extendedTimes = getExtendedTimes(times);

  let styleGridTemplateRows = '[days] auto';
  extendedTimes.sort().map((time) => (styleGridTemplateRows += ` [time-${time}] 0.15fr`));

  return (
    <>
      <div className={styles.schedule} style={{ gridTemplateRows: styleGridTemplateRows }}>
        {weekdays.map((weekday) => (
          <span className={`${styles.weekDay} ${styles.gridHeader}`} key={weekday} aria-hidden="true">
            {weekday}
          </span>
        ))}

        {/* Slicing last element so the last hour is not displayed in the time column */}
        {/* Only show exact and half hours */}
        {extendedTimes.slice(0, -1).map((time) => (
          <h2 className={styles.timeSlot} style={{ gridRow: `time-${time}` }} key={time}>
            {time.substring(2, time.length) === '00' || time.substring(2, time.length) === '30'
              ? time.substring(0, 2) + ':' + time.substring(2, time.length)
              : ''}
          </h2>
        ))}

        {Object.keys(lessons).map((day) => {
          return (
            <React.Fragment key={day}>
              {windowWidth < 900 && <h3 className={styles.dayTitle}> {weekdaysTranslation[day]} </h3>}
              {lessons[day].map(({ name, startTime, endTime, lesson_id, teacherName, teacherSlug, ageGroup }) => (
                <TimetableEventCard
                  key={`${startTime}_${day}_${name}`}
                  day={day}
                  name={name}
                  startTime={startTime}
                  endTime={endTime}
                  lesson_id={lesson_id}
                  teacherName={teacherName}
                  teacherSlug={teacherSlug}
                  ageGroup={ageGroup}
                  courseType={courseType}
                />
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
};

export default TimetableEvents;
