import * as styles from './toggle.module.scss';

import React from 'react';

const Toggle = ({ callback }) => {
  return (
    <div className={styles.wrapper}>
      <input type="checkbox" className={styles.checkboxToggle} id="toggle" onChange={() => callback()} />
      <div className={styles.content}>
        <label htmlFor="toggle">
          <div className={styles.toggle}></div>
          <div className={styles.names}>
            <span className={styles.fitness}>Fitness</span>
            <span className={styles.dance}>Danza</span>
          </div>
        </label>
      </div>
    </div>
  );
};

export default Toggle;
